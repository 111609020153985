
const Footer = () => {
    return (<footer className="static bottom-0 py-6 md:px-8 md:py-0">
    <div className="container flex flex-col items-center justify-between gap-4 h-[24rem] md:h-24 md:flex-row">
      <p className="text-balance text-center text-sm leading-loose text-muted-foreground md:text-left">
      Email us at <a href="mailto:help@bookanerd.com">help@bookanerd.com </a>

©2024 Book a Nerd. All Rights Reserved.
        </p>
    </div>
    </footer>)
}

export default Footer;