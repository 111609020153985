import React from "react";

function Workshops() {
  return (
    <div>
      <h2>Workshops</h2>
    </div>
  );
}

export default Workshops;