import { ZoomMtg } from "@zoomus/websdk";
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded";
import {  Component } from "react";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.16.0/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");
//https://zoom.us/oauth/authorize?response_type=code&client_id=ExV7EE42RmiJJWKRQqbzcw&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F

const client = ZoomMtgEmbedded.createClient();
const authEndpoint = "http://localhost:4000/zoom";
const sdkKey = "ExV7EE42RmiJJWKRQqbzcw";
const meetingNumber = "4382051679";
const passWord = "mrj28j";
const role = 0;
const userName = "React";
const userEmail = "";
const registrantToken = "";
const zakToken = "";
const leaveUrl = "http://localhost:3000";


class ZoomWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startClass: true
    };

    this.getSignature = this.getSignature.bind(this);
    this.startMeeting = this.startMeeting.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  getSignature(e) {
    this.setState({ startClass: false });

    console.log("getSignature");
    e.preventDefault();

    fetch(authEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: this.props.isTeacher ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.startMeeting(response.signature);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  startMeeting(signature) {
    let meetingSDKElement = document.getElementById("meetingSDKElement");

    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      customize: {
        meetingInfo: [
          "topic",
          "host",
          "mn",
          "pwd",
          "telPwd",
          "invite",
          "participant",
          "dc",
          "enctype",
        ],
        toolbar: {
          buttons: [
            {
              text: "Custom Button",
              className: "CustomButton",
              onClick: () => {
                console.log("custom button");
              },
            },
          ],
        },
      }
    });
    try {
      client.join({
        signature: signature,
        sdkKey: sdkKey,
        meetingNumber: meetingNumber,
        password: passWord,
        userName: userName,
        userEmail: userEmail,
        tk: registrantToken,
        zak: zakToken,
        error: () => {
          console.log("error");
        },
        success: () => {
          console.log("success");
        },
      }).then(() => {
        console.log("success1");
      }).catch((error) => {
        console.error("1", error);
      });
    } catch (error) {
      console.error("2", error);
    }
  }

  render() {

        return (<div className="Zoom-window-component">
        <h1>Zoom Meeting SDK Sample React</h1>
        {this.state.startClass && (
          <button className="App-button" onClick={this.getSignature}>
            Join Private Tutor Class
          </button>
        )}
        <div id="meetingSDKElement">
          {/* Zoom Meeting SDK Component View Rendered Here */}
        </div>
      </div>)
    }
};

export default ZoomWindow;