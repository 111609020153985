/** @format */

import "../App.css";
import { useState } from "react";
import ZoomWindow from "./ZoomWindow";
import { useNavigate } from "react-router-dom";
import LoginButton from "./LoginButton";
import { Auth0Provider } from "@auth0/auth0-react";

function Playground({ video }) {
  const [isTeacher, setIsTeacher] = useState(false);

  const navigate = useNavigate();
  return (
    <Auth0Provider
      domain="dev-v1fv3srdotscz2dg.eu.auth0.com"
      clientId="OAmJUJZgR98YSVe3CV2JvL47kC2KSWaD"
      audience="https://dev-v1fv3srdotscz2dg.eu.auth0.com/api/v2/"
      redirectUri="http://localhost:3000/playground"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <div className="App">
        <div className="App-code">
          <iframe
            style={{
              width: "100%",
              height: 900,
              outline: "1px solid #252525",
              border: 0,
              borderRadius: 8,
              marginBottom: 16,
              zIndex: 100,
            }}
            //src="https://codesandbox.io/p/devbox/wispy-firefly-fndl97?file=%2Fsrc%2Findex.css%3A13%2C38"
            src="https://codesandbox.io/signin"
            title="Code sandbox iframe"
          ></iframe>
        </div>
        <div className="App-panel">
          <ZoomWindow isTeacher={isTeacher} />
          <div>
            <input
              type="checkbox"
              value={isTeacher}
              onChange={() => setIsTeacher(!isTeacher)}
            />
            Join as teacher
          </div>
          <div>
            hello
            <LoginButton />
          </div>

          <div>{video}</div>
          <button className="App-button" onClick={() => navigate("/")}>
            Main page
          </button>
          {/* <iframe
            width="560"
            height="315"
            src="http://localhost:4000/video"
            frameborder="0"
            allowfullscreen
          ></iframe> */}

          <iframe
            width="560"
            height="315"
            src="http://lala.bookanerd.io/"
            // src="https://workers-playground-winter-silence-ae34.xeli88.workers.dev/"
            frameborder="0"
            allowfullscreen
            title="Lala iframe"
          ></iframe>
        </div>
      </div>
    </Auth0Provider>
  );
}

export default Playground;
