import React from "react";

function Schedule() {    
  return (
    <div>
      <h2>Schedule</h2>
    </div>
  );
} 

export default Schedule;