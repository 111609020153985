/** @format */

// import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Playground from "./components/Playground";
import Conference from "./components/conference/Conference";
import Speakers from "./components/conference/Speakers";
import Schedule from "./components/conference/Schedule";
import Sponsors from "./components/conference/Sponsors";
import Venue from "./components/conference/Venue";
import Registration from "./components/conference/Registration";
import FAQ from "./components/conference/FAQ";
import Contact from "./components/conference/Contact";
import Privacy from "./components/conference/Privacy";
import CodeOfConduct from "./components/conference/CodeOfConduct";
import Talks from "./components/conference/Talks";
import Workshops from "./components/conference/Workshops";
import YoutubeEmbed from "./components/YoutubeEmbed";
import Header from "./components/header";
import Footer from "./components/footer";

function App() {
  var video = <YoutubeEmbed embedId="7x7x3SN-kx4" />;
  // https://youtu.be/7x7x3SN-kx4


  return (
    <Router>
      <div className="App relative flex min-h-screen flex-col bg-background">
        <Header/>
        
      
        <main className="flex-1">
          <Routes>
            <Route path="/" element={<Home video={video} />} />
            <Route path="/playground" element={<Playground video={video} />} />
            <Route path="/conference" element={<Conference />} />
            <Route path="/conference/speakers" element={<Speakers />} />
            <Route path="/conference/schedule" element={<Schedule />} />
            <Route path="/conference/sponsors" element={<Sponsors />} />
            <Route path="/conference/venue" element={<Venue />} />
            <Route path="/conference/registration" element={<Registration />} />
            <Route path="/conference/faq" element={<FAQ />} />
            <Route path="/conference/contact" element={<Contact />} />
            <Route path="/conference/privacy" element={<Privacy />} />
            <Route path="/conference/code-of-conduct" element={<CodeOfConduct />} />
            <Route path="/conference/talks" element={<Talks />} />
            <Route path="/conference/workshops" element={<Workshops />} />
          </Routes>
        </main>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
