import React from "react";

function Speakers() {
  return (
    <div>
      <h2>Speakers</h2>
    </div>
  );
}

export default Speakers;