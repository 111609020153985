import React from "react";

const Conference = () => {
  return (
    <div>
      <h1>Conference</h1>
      <p>
        This is a conference page.
      </p>
    </div>
  );
}

export default Conference;