import React from "react";

function Registration() {
  return (
    <div>
      <h2>Registration</h2>
    </div>
  );
}

export default Registration;