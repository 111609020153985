import React from "react";

function Venue() {
  return (
    <div>
      <h2>Venue</h2>
    </div>
  );
}  

export default Venue;