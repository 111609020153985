import React from "react";

function CodeOfConduct() {
  return (
    <div>
      <h2>Code of Conduct</h2>
    </div>
  );
}

export default CodeOfConduct;