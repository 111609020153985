import { useState, useCallback } from 'react';
import Drawer from '../Drawer';
import MainNavigation from '../mainNavigation';

const Header = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => setOpen(true), []);
  
    const handleClose = useCallback(() => setOpen(false), []);



    return (<header className="sticky top-0 z-50 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <MainNavigation/>
        {/*<button className="App-button" onClick={handleOpen}>
          ClickMe Open Drawer
        </button>
<Drawer anchor="right" open={open} onClose={handleClose}/>*/}
    </header>)
}

export default Header;