/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import LoginButton from "./LoginButton";
import Flex from './styles/flex';
import { Power , BrainCircuit , GraduationCap , Goal} from 'lucide-react';

const LinkBox = ({image, icon, text}) => {
  return (<div className="md:basis-1/2 p-2">
    <div className="rounded border border-white">
      <img src={image}/>
      <div className="background-grey text-white">
        <div className="float-left m-2">
          {icon}
          </div>
        <Flex className="p-2">
          
          {text}
        </Flex>
        
      </div>
    </div>
  </div>)
}

function Home() {
  const navigate = useNavigate();
  return (
    <Flex className="home flex-column scroll-auto">
      <Flex className="flex-auto flex-row flex-wrap">
        <LinkBox
          image={"/click_to_play.png"}
          icon={<Power/>}
          text={'PLAY AROUND - Use our Sandbox to learn how to code and follow along with our tutorials… If you get stuck just ask for help from one of our tutors!'}
        />
        <LinkBox
          image={"/we_teach_kids.png"}
          icon={<BrainCircuit />}
          text={'FOR KIDS - We offer FUN one-on-one programming lessons for children ages 8 to 12 where they will program their own games and designs!'}
        />
        
        <LinkBox
          image={"/for_schools.png"}
          icon={<GraduationCap />}
          text={'FOR SCHOOLS - We create fun programming courses and boot camps that you can offer your students…Perfect for summer school!'}
        />
        <LinkBox
          image={"/b2b.png"}
          icon={<Goal />}
          text={'B2B SERVICES - Do you have a product you want to develop or want to teach your team how to use your software? We can help!'}
        />
      </Flex>
    </Flex>
  );
}

export default Home;
